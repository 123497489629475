import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/monthlyPerformanceTotalModals";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import "../MonthlyPerformanceTotalModals.scss";
import { IconAlert } from "../../../../../common/icons/IconAlert/IconAlert";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import {
  BonusBreakdownLineData,
  BonusPercent,
  DifferentialBonusEntry,
  GroupEntry,
  GroupNonBonusableEntry,
} from "../../../../../common/interfaces/monthlyPerformanceDash";
import { ANA_MARKET_CODES } from "../../../../../common/constants/market-constants";
import { useAppSelector } from "../../../../../store";
import { getABOName } from "../../../../../common/leaf/LeafProfile/LeafABOName/LeafABOName";

type Table = {
  aboBonusPercent: string;
  aboGpv: string;
  personalBV: string;
  bonusPercent: string;
  personalBonusAmount: string;
  nonBonusable: string;
  total: string;
};

type PerformanceTotal = {
  title: string;
  subTitle: string;
  table: Partial<Table>;
};

type BonusData = {
  bonusPercent: BonusPercent;
  differentialBonus: DifferentialBonusEntry[];
};

const DifferentialBonusModal = ({
  openModal,
  onCloseModal,
  performanceData,
  performanceTotal,
  isFetching,
  isError,
}: {
  openModal: boolean;
  onCloseModal: Function;
  performanceData: Partial<BonusData>;
  performanceTotal: Partial<BonusBreakdownLineData>;
  isFetching: boolean;
  isError: boolean;
}) => {
  const { t } = useTranslation("monthlyPerformanceTotalModals");
  const user = useAppSelector((state) => state.boot?.user);
  const anaMarket = ANA_MARKET_CODES.includes(user.aff);

  const contents = t("differential", {
    returnObjects: true,
    defaultValue: defaultContent.differential,
  }) as PerformanceTotal;

  if (isError) return <></>;

  const renderLoader = (children: React.ReactNode) => (
    <LeafRectangleLoader isLoading={isFetching} height="2rem" width="auto">
      {children}
    </LeafRectangleLoader>
  );

  const findNonBonusableAmount = (isoCurrencyCode: string, groupNonBonusable: GroupNonBonusableEntry[]) => {
    const nonBonusable = groupNonBonusable?.find(
      (item: { isoCurrencyCode: string }) => isoCurrencyCode === item.isoCurrencyCode,
    );
    return nonBonusable?.groupBV?.formatted || 0;
  };

  return (
    <LeafModal open={openModal} onCloseCallbackFn={onCloseModal}>
      <div className="monthly-bonus-modal_bonus-header">
        <h2>{contents?.title}</h2>
        <p>
          <Trans defaults={contents?.subTitle} components={{ 1: <strong /> }} />
        </p>
      </div>
      <div className="monthly-bonus-modal_content">
        <div className="monthly-bonus-modal_bonus-details">
          {renderLoader(
            performanceData?.differentialBonus?.map((item: DifferentialBonusEntry) => (
              <div className="wrapper" key={item.aboName}>
                <div className="abo">
                  <span>
                    {getABOName({
                      name: item.aboName,
                      localName: item.localizedName,
                      privacyFlag: false,
                      languageCode: item.isoLanguageCode,
                      confidentialText: "",
                      notAvailableText: "",
                    })}
                  </span>
                  <div className="abo-details">
                    <span>
                      <Trans
                        defaults={contents?.table?.aboBonusPercent}
                        values={{ aboBonusPercent: item.bonusPercent.formatted }}
                      />
                    </span>
                    <span> | </span>
                    <span>
                      <Trans defaults={contents?.table?.aboGpv} values={{ aboGpv: item.totalGPV.formatted }} />
                    </span>
                  </div>
                </div>
                {item.groupBonus?.map((groupItem: GroupEntry, index: number) => (
                  <div key={`${item.aboName}-${groupItem.isoCurrencyCode}-${index}`}>
                    <div className="personal-bv">
                      <span>{contents?.table?.personalBV}</span>
                      <span>{groupItem.groupBV.formatted}</span>
                    </div>
                    <div className="bonus-percent">
                      <span>
                        <Trans
                          defaults={contents?.table?.bonusPercent}
                          values={{
                            bonusPercent: `${performanceData?.bonusPercent?.formatted} - ${item.bonusPercent?.formatted}`,
                          }}
                        />
                      </span>
                      <span>x {item.differentialPercent?.formatted}</span>
                    </div>
                    <div className="current-total">
                      <span>{contents?.table?.personalBonusAmount}</span>
                      <span>{groupItem.differentialAmount.formatted}</span>
                    </div>
                    {anaMarket && (
                      <div className="non-bonusable-leg">
                        <span>{contents?.table?.nonBonusable}</span>
                        <span className="non-bonusable-leg_content">
                          <span>{findNonBonusableAmount(groupItem.isoCurrencyCode, item.groupNonBonusable)}</span>
                          <IconAlert />
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )),
          )}
          {renderLoader(
            <div className="personal-bonus-total">
              <span>{contents?.table?.total}</span>
              <span>{performanceTotal?.amount}</span>
            </div>,
          )}
        </div>
      </div>
    </LeafModal>
  );
};

export default DifferentialBonusModal;
