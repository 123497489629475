const content: { [key: string]: any } = {
  pageTitle: "Strong Start Incentive+",
  bannerTile: {
    earning: "Great Job! You are earning the Strong Start Incentive+.",
    tracking: "Keep going to earn the Strong Start Incentive+!",
    notTracking: "It is no longer possible for you to achieve the Strong Start Incentive+",
    notEligible: "You are not eligible for the Strong Start Incentive+ for {month} {year}.",
    manuallyApproved: "The Strong Start Incentive+ has been manually approved.",
    held: "Your award is on hold.",
    youreEligibleToEarnThisIncentiveTimes:
      "You're eligible to earn this incentive <1>{times} more times</1> until {month} {year}.",
    descriptionStart:
      "This incentive must be started during your registration month or the two months following and then must be consecutively achieved <1>every</1> month for up to 12 months.",
    descriptionTwoPayments:
      "You're eligible to earn this incentive <1>{times} more times</1> until {month} {year}. There is an opportunity to achieve <2>{paymentOne}</2> and <3>{paymentTwo}</3> if you meet these monthly requirements for {numMonthsOne} and {numMonthsTwo} months.",
    descriptionTwoPaymentsOnePaymentRemaining:
      "You're eligible to earn this incentive <1>{times} more times</1> until {month} {year}. There is an opportunity to achieve <2>{paymentOne}</2> if you meet these monthly requirements for {numMonthsOne} months.",
    descriptionOnePayment:
      "You need to meet these monthly requirements for the next <1>{numMonths}</1> months consecutively to earn the next payment of <2>{payment}</2>.",
    moreInformation: "If you require more information, please contact Customer Support for further details.",
    learnMore: "Learn More",
    strongStartIncentiveEarnings: "Strong Start Incentive+ Earnings",
  },
  progressTile: {
    title: "Track your progress",
    requirementLabel: "You've met <1>{current} of {needed}</1> requirements to earn this incentive.",
    eligibilityWindowDetails: "Eligibility Window Details",
  },
  requirements: {
    sectionHeader: "How do I qualify? Here is the list of requirements.",
    personal: {
      title: "{volume} PPV",
      description: "",
    },
  },
  eligibilityModal: {
    title: "Eligibility",
    subtitle: "Program Details",
    eligibilityDescriptionSecondFullMonth:
      "<1>You must achieve the 2nd full month after your registration month</1> in order to continue in the Strong Start Incentive+.",
    eligibilityDescriptionEveryMonth:
      "You must achieve the requirements <1>every month</1> in order to continue in the Strong Start Incentive+.",
    entryDate: "Entry Date",
    startDate: "Start Date",
    endDate: "End Date",
    programDescription:
      "The Strong Start Incentive+ rewards behaviors for achieving baseline requirements month after month. Earn rewards after 3, 6, and 12 months of achievement.",
    moreInformation:
      "For detailed information about eligibility, refer to <1>Amway.com/en_US/money-and-rewards</1> for complete details.",
  },
} as const;

export default content;
