import { useState } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/qcGrowth";
import { IconBadge } from "../../../common/icons/IconBadge/IconBadge";
import { MultiplierData } from "../../../common/interfaces/groupGrowthIncentives";
import { getPerformanceYearShort } from "../../../common/util/period";
import { IconPlainTable } from "../../../common/icons/IconPlainTable/IconPlainTable";
import { IncentiveCalculation } from "../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconMultiply } from "../../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../../common/icons/IconEquals/IconEquals";
import { MultiplierBaseResponse } from "../../../common/interfaces/multiplierBase";
import { QCGMultiplierBase } from "./QCGMultiplierBase/QCGMultiplierBase";

type Props = {
  multiplierData: MultiplierData[];
  isLoading: boolean;
  selectedPeriod: string;
};

export default function QCGIncentiveCalculation({ isLoading, multiplierData, selectedPeriod }: Props) {
  const { t } = useTranslation(["qcGrowth"]);
  const [multiplierBaseModalIsOpen, setMultiplierBaseModalIsOpen] = useState<boolean>(false);
  const PY = getPerformanceYearShort(selectedPeriod);

  const getDefaultContent = (type: string) => {
    return type === "leg" ? defaultContent.incentiveCalc.leg : defaultContent.incentiveCalc.incentive;
  };

  const getIconForBase = (item: MultiplierData) => {
    return item.Type === "Leg" ? <IconBadge width="1.3125rem" height="1.75rem" /> : <IconPlainTable />;
  };

  const getCallbackFunction = (item: MultiplierData) => {
    if (item.Type === "Incentive") {
      return openMultiplierBase();
    }
  };

  const openMultiplierBase = () => {
    setMultiplierBaseModalIsOpen(!multiplierBaseModalIsOpen);
  };

  const mockMultiplierBaseData: MultiplierBaseResponse = {
    MultiplierBaseDetails: [
      {
        MultiplierType: "foundersPlatinumAbove",
        BonusAwards: [
          {
            BonusAward: 30,
            BonusTotal: "$10",
          },
          {
            BonusAward: 40,
            BonusTotal: "$10",
          },
          {
            BonusAward: 50,
            BonusTotal: "$10",
          },
          {
            BonusAward: 4,
            BonusTotal: "$10",
          },
          {
            BonusAward: 20,
            BonusTotal: "$10",
          },
        ],
        Achieved: false,
        DisplaySectionHeader: false,
      },
      {
        MultiplierType: "diamondAbove",
        BonusAwards: [],
        Achieved: false,
        DisplaySectionHeader: false,
      },
    ],
    Status: "NOT_TRACKING",
    CallResults: [],
    Error: "",
  };

  const renderModal = (item: MultiplierData) => {
    if (item.Type === "Incentive") {
      return (
        <QCGMultiplierBase
          qcgResponseData={{
            multiplierBaseData: mockMultiplierBaseData,
            isLoading: false,
            errorStatus: false,
            refetch: () => console.log("Mock refetch function"),
          }}
          modalIsOpen={multiplierBaseModalIsOpen}
          toggleModal={openMultiplierBase}
          performanceYear={PY}
          multiplierBase={item.Base}
        />
      );
    }
  };

  const incentiveCalculationTile = (item: MultiplierData) => {
    const type = item.Type.toLowerCase();
    return (
      <div className="FGGIncentiveCalculation" key={type}>
        <IncentiveCalculation
          isLoading={isLoading}
          base={{
            title: t(`incentiveCalc.${type}.base`, getDefaultContent(type).base).toString(),
            amount: item.Base,
            icon: getIconForBase(item),
            callbackFn: () => getCallbackFunction(item),
          }}
          multiplier={{
            title: t(`incentiveCalc.${type}.multipler`, getDefaultContent(type).multipler).toString(),
            amount: item.Multiplier,
            symbolIcon: <IconMultiply />,
          }}
          total={{
            title: t(`incentiveCalc.${type}.total`, getDefaultContent(type).total, {
              performanceYear: PY,
            }).toString(),
            amount: item.Total,
            symbolIcon: <IconEquals />,
          }}
        />
        {renderModal(item)}
      </div>
    );
  };

  return <>{multiplierData.map((item: MultiplierData) => incentiveCalculationTile(item))}</>;
}
