import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import LeafRectangleLoader from "../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import defaultContent from "../../content/ttci";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { Config } from "../../common/interfaces/config";
import { ErrorPage, ERROR_TYPE } from "../ErrorPage/ErrorPage";
import { LeafMessageBox } from "../../common/leaf/LeafMessageBox/LeafMessageBox";
import TEALIUM from "../../common/enums/tealium-enums";
import { useGetTtciApiQuery } from "../../services/corePlusIncentivesApi";
import { TtciData, VolumeData } from "../../common/interfaces/ttciData";
import { ANA_MARKET_CODES } from "../../common/constants/market-constants";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import BannerTile from "./BannerTile/BannerTile";
import { RequirementTrackingTile } from "./RequirementTrackingTile/RequirementTrackingTile";
import "./TTCI.scss";
import EligibilityPinTile from "./EligibilityPinTile/EligibilityPinTile";
import { returnDefaultTtciData, getStatusForTealium } from "./ttciUtils";

type Props = {
  mockData?: TtciData;
  mockConfig?: Config;
};

export default function TTCI({ mockData, mockConfig }: Props) {
  const { user, configuration, shellConfig } = useAppSelector((state) => state.boot);
  const { isComingSoon = false } = shellConfig;
  const { t } = useTranslation(["ttci"]);
  const dispatch = useAppDispatch();
  const locale = storedLocale();
  const isAna = ANA_MARKET_CODES.includes(user.aff);
  const { selectedPeriod, formattedPeriods } = useAppSelector((state) => state.period);
  const isNewPerformanceYear = isComingSoon;

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch, formattedPeriods]);

  const {
    data: payloadData,
    isLoading: isQueryLoading,
    isFetching,
    isError,
  } = useGetTtciApiQuery(
    {
      baseUrl: configuration.incentivesApiUrl,
      bonusPeriod: selectedPeriod,
      affAbo: `${user.aff}-${user.abo}`,
    },
    {
      skip: isComingSoon,
    },
  );

  /**
   * Service will return 200 but with only an errors prop so we need to make sure the error caused no data to return
   */
  const checkNoDataError = (payloadData: TtciData) => {
    return payloadData && !isComingSoon ? payloadData.bonus === undefined || payloadData.status === undefined : false;
  };
  const errorNoDataReturned = checkNoDataError(payloadData);
  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [ttciData, setTtciData] = useState<TtciData>(returnDefaultTtciData());

  const learnMoreLink = configuration.dashboardLinks?.learnMore.twoTimeCashIncentive || "";
  const showTtciEligButton = configuration.newTtciPage?.showTtciEligibilityButton || false;

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if ((isQueryLoading || isFetching) && !mockData) {
      setIsLoading(true);
    }
  }, [isQueryLoading, isFetching, selectedPeriod, user, configuration, mockData]);

  useEffect(() => {
    if ((errorNoDataReturned || isError) && !mockData) {
      setErrorStatus(true);
      setIsLoading(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching, selectedPeriod, mockData, errorNoDataReturned]);

  // once loading has finished and data is resolved, save to state and set loading to false
  useEffect(() => {
    if (mockData) {
      setTtciData(mockData);
      setIsLoading(false);
      setErrorStatus(false);
    }
    if (!isQueryLoading && !isFetching && !isError && !errorNoDataReturned) {
      // Do not set payload(undefined) to override ttciData if comingsoon is true
      if (!isComingSoon) {
        setTtciData(payloadData);
      }
      setIsLoading(false);
      setErrorStatus(false);
      track(
        TEALIUM.U_TAG_METHOD.VIEW,
        TEALIUM.EVENT_NAME.CLICK_ACTION,
        TEALIUM.CATEGORY.TWO_TIME_CASH_INCENTIVE,
        TEALIUM.CATEGORY.TWO_TIME_CASH_INCENTIVE,
        `${getStatusForTealium(!isComingSoon ? payloadData : ttciData)}:${selectedPeriod}`,
        "",
      );
    }
  }, [payloadData, isError, isQueryLoading, isFetching, selectedPeriod, mockData, errorNoDataReturned]);

  const {
    displayVCS,
    displayPPV,
    vcsCumulative,
    ppvCumulative,
    ppv,
    vcsPercent,
    displayAnaMissedToast,
    displayTrackingGraphics,
    displayDateError = false,
  } = ttciData;

  const displayErrorPage = (): boolean => {
    if (displayDateError) {
      return true;
    }
    return errorStatus;
  };

  const ErrorComponent = () => {
    let errorType = ERROR_TYPE.GENERIC_ERROR;
    if (displayDateError) {
      errorType = ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD;
    }
    return displayErrorPage() ? <ErrorPage errorType={errorType} isLoading={isLoading} /> : <></>;
  };

  const formatVcsPercent = () => {
    const percentage = vcsCumulative / ppvCumulative;
    return Math.trunc(percentage * 100);
  };

  const returnReqTrackingComponent = (
    volumeData: VolumeData = {} as VolumeData,
    requirementType: "ppv" | "vcs",
    componentDisplayStatus: boolean,
  ) => {
    return (
      componentDisplayStatus && (
        <RequirementTrackingTile
          isLoading={isLoading}
          currentValue={requirementType === "vcs" ? formatVcsPercent() : volumeData.value}
          targetValue={volumeData.target}
          period={selectedPeriod}
          shouldShowGraphics={displayTrackingGraphics}
          requirementType={requirementType}
          vcsCumulative={vcsCumulative}
          ppvCumulative={ppvCumulative}
        />
      )
    );
  };

  const getQualifiySection = () => {
    if (isNewPerformanceYear) return <></>;

    return (
      <section className={`ttci__row-two ${isAna ? "" : "ttci__non-ana-row-two"}`}>
        <div>
          <LeafRectangleLoader isLoading={false} width="18.75rem" height="1.5rem">
            <h3 className={`ttci__section-title ttci__qualify-title`}>
              {`${t("howQualify", defaultContent["howQualify"])}`}
            </h3>
          </LeafRectangleLoader>
          <div className="ttci__row-two-tiles">
            <div className="ttci__eligible-pin-tile">
              <EligibilityPinTile
                ttciData={ttciData}
                period={selectedPeriod}
                locale={locale}
                country={user.isoCountryCode}
                isLoading={isLoading}
                showTtciEligButton={showTtciEligButton}
              ></EligibilityPinTile>
            </div>
            <div className={`ttci__lower-section ${isAna ? "" : "hidden"}`}>
              <div className={`ttci__culmulative-tile`}>
                {returnReqTrackingComponent(vcsPercent, "vcs", displayVCS)}
              </div>
              <div className={`ttci__monthly-average-tile`}>{returnReqTrackingComponent(ppv, "ppv", displayPPV)}</div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <main>
      <ErrorComponent />
      <div className={`ttci ${displayErrorPage() ? "hidden" : ""}`}>
        <section className="ttci__header">
          <h1 id="ttci_header">{`${t("pageTitle", defaultContent["pageTitle"])}`}</h1>
          <span className="ttci__long-date">
            <TimeStamp dateFormat={"long"} />
          </span>
        </section>
        <div className={`ttci-message-box ${displayAnaMissedToast ? "" : "hidden"}`}>
          <LeafMessageBox color={"red"}>{t("volumeReqNotMet", defaultContent["volumeReqNotMet"])}</LeafMessageBox>
        </div>
        <div className={`ttci__body ${isAna ? "" : "ttci__non-ana-body"}`}>
          <section className={`ttci__row-one${isNewPerformanceYear ? " ttci__coming-soon" : ""}`}>
            <div className="ttci__banner-tile">
              <BannerTile
                ttciData={ttciData}
                isNewPerformanceYear={isNewPerformanceYear}
                period={selectedPeriod}
                country={user.isoCountryCode}
                locale={locale}
                learnMoreLink={learnMoreLink}
                isLoading={isLoading}
              ></BannerTile>
            </div>
            <div className="ttci__placeholder-tile" hidden={!isAna}></div>
          </section>
          {getQualifiySection()}
        </div>
        <div className="ttci__footer">
          <BonusInfoFooter />
        </div>
      </div>
    </main>
  );
}
