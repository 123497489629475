import { PPERubyRequirement } from "../PerfPlusEliteTypes";

export const mockPerfPlusRuby: PPERubyRequirement = {
  Name: "Performance Plus Ruby",
  Met: false,
  Percent: {
    Current: {
      Actual: 0,
      Formatted: "0%",
    },
    Target: {
      Actual: 2,
      Formatted: "2%",
    },
    Remaining: {
      Actual: 2,
      Formatted: "2%",
    },
  },
  Volume: {
    Current: {
      Actual: 100,
      Formatted: "100",
    },
    Target: {
      Actual: 10000,
      Formatted: "10,000",
    },
    Remaining: {
      Actual: 9900,
      Formatted: "9,900",
    },
  },
  Multiplier: "2%",
};

export const mockPerfPlusRubyInProgress: PPERubyRequirement = {
  Name: "Performance Plus Ruby",
  Met: false,
  Percent: {
    Current: {
      Actual: 1,
      Formatted: "1%",
    },
    Target: {
      Actual: 2,
      Formatted: "2%",
    },
    Remaining: {
      Actual: 1,
      Formatted: "1%",
    },
  },
  Volume: {
    Current: {
      Actual: 5100,
      Formatted: "5100",
    },
    Target: {
      Actual: 10000,
      Formatted: "10,000",
    },
    Remaining: {
      Actual: 4900,
      Formatted: "4,900",
    },
  },
  Multiplier: "2%",
};

export const mockPerfPlusRubyMet: PPERubyRequirement = {
  Name: "Performance Plus Ruby",
  Met: true,
  Percent: {
    Current: {
      Actual: 2.5,
      Formatted: "2.5%",
    },
    Target: {
      Actual: 2,
      Formatted: "2%",
    },
    Remaining: {
      Actual: 0,
      Formatted: "0%",
    },
  },
  Volume: {
    Current: {
      Actual: 11000,
      Formatted: "11,000",
    },
    Target: {
      Actual: 10000,
      Formatted: "10,000",
    },
    Remaining: {
      Actual: 0,
      Formatted: "0",
    },
  },
  Multiplier: "2%",
};

export const mockPerfEliteRuby: PPERubyRequirement = {
  Name: "Performance Elite Ruby",
  Met: false,
  Percent: {
    Current: {
      Actual: 0,
      Formatted: "0%",
    },
    Target: {
      Actual: 4,
      Formatted: "4%",
    },
    Remaining: {
      Actual: 4,
      Formatted: "4%",
    },
  },
  Volume: {
    Current: {
      Actual: 100,
      Formatted: "100",
    },
    Target: {
      Actual: 12500,
      Formatted: "12,500",
    },
    Remaining: {
      Actual: 12400,
      Formatted: "12,400",
    },
  },
  Multiplier: "4%",
};

export const mockPerfEliteRubyInProgress: PPERubyRequirement = {
  Name: "Performance Elite Ruby",
  Met: false,
  Percent: {
    Current: {
      Actual: 2.5,
      Formatted: "2.5%",
    },
    Target: {
      Actual: 4,
      Formatted: "4%",
    },
    Remaining: {
      Actual: 1.5,
      Formatted: "1.5%",
    },
  },
  Volume: {
    Current: {
      Actual: 11000,
      Formatted: "11,000",
    },
    Target: {
      Actual: 12500,
      Formatted: "12,500",
    },
    Remaining: {
      Actual: 1500,
      Formatted: "1,500",
    },
  },
  Multiplier: "4%",
};

export const mockPerfEliteRubyMet: PPERubyRequirement = {
  Name: "Performance Elite Ruby",
  Met: true,
  Percent: {
    Current: {
      Actual: 5,
      Formatted: "5%",
    },
    Target: {
      Actual: 4,
      Formatted: "4%",
    },
    Remaining: {
      Actual: 0,
      Formatted: "0%",
    },
  },
  Volume: {
    Current: {
      Actual: 15000,
      Formatted: "15,000",
    },
    Target: {
      Actual: 12500,
      Formatted: "12,500",
    },
    Remaining: {
      Actual: 0,
      Formatted: "0",
    },
  },
  Multiplier: "4%",
};
