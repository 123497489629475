import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import partition from "lodash/partition";
import { useEffect, useState } from "react";
import { formatReqVolume } from "@amwaycommon/acc-mixins/l10n";
import { useAppDispatch, useAppSelector } from "../../../store";
import TitleSkeletonLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import defaultContent from "../../../content/newAboActivation";
import "./PathToBronze.scss";
import { storedLocale } from "../../impersonation/util";
import { ERROR_TYPE, ErrorPage } from "../../ErrorPage/ErrorPage";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import TEALIUM from "../../../common/enums/tealium-enums";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import { BonusHist } from "../../../common/leaf/LeafBadgeGrid/LeafBadgeGrid";
import { TimeStamp } from "../../../common/components/TimeStamp/TimeStamp";
import { BonusInfoFooter } from "../../../common/components/BonusInfoFooter/BonusInfoFooter";
import { VcsPercentRequirementTile } from "../../../common/components/RequirementTiles/VcsPercentRequirementTile/VcsPercentRequirementTile";
import { VolumeRequirementTile } from "../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import { LeafVolumeLabel } from "../../../common/leaf/LeafVolumeLabel/LeafVolumeLabel";
import { BronzeBannerNewPyToast } from "../BronzeBannerToast/NewPYBanner/NewPYBanner";
import LegRequirementTile from "./LegRequirementTile/LegRequirementTile";
import {
  getVolReqData,
  getTrackingReqData,
  isLatamMarketAndNotPastPeriod,
  getLegRequiredVolume,
  GetVolumeTypeLabel,
} from "./pathToBronzeUtils";
import TrackingRequirementTile from "./TrackingRequirementTile/TrackingRequirementTile";
import CalcDescTile from "./CalcDescriptionTile/CalcDescTile";
import EligibilityModal from "./EligibilityModal/EligibilityModal";
import { PtbBannerTile } from "./PtbBannerTile/PtbBannerTile";
import EligibilityTile from "./EligibilityTile/EligibilityTile";
import { FetchPtbData } from "./PathToBronzeDataLayer";

type Prop = {
  showHeaderFooter?: boolean;
};

export default function PathToBronze(prop: Prop) {
  const { t } = useTranslation(["newAboActivation"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { user } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const locale = storedLocale();
  const { showHeaderFooter = true } = prop;

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const { data, isLoading, errorStatus } = FetchPtbData(selectedPeriod);

  const hasExceptionStatus = data.denied || data.held || data.manualApproval;

  const ineligibleForPY = data.ineligibleForPY;
  const graduated = data.graduated;

  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);

  const volumeNeeded = formatReqVolume(data.legRequiredVolume.volumeRequired, locale, user.isoCountryCode);
  const volumeTypeLabel = GetVolumeTypeLabel(data.legRequiredVolume.requirement);

  const formatVolume = (volume: number) => formatReqVolume(volume, locale, user.isoCountryCode);

  const { GPVTile, PPVTile, VCSTile, displayVCS } = getVolReqData(data);
  const trackingRequirementData = getTrackingReqData(data);

  const getHistory = () => {
    const [qualified, other] = partition(
      data.bonusHistory,
      (el) => el.status === QUALIFICATION_STATUS.QUALIFIED || el.status === QUALIFICATION_STATUS.TRACKING,
    );
    if (qualified.length >= data.awardCap.target || data.awardCap.remaining === 0) {
      // Once eligiblity met (graduated), set all other period status to un available
      return [
        ...qualified,
        ...other.map((el) => {
          return { status: QUALIFICATION_STATUS.UN_AVAILABLE, period: el.period };
        }),
      ] as BonusHist[];
    }

    // Set status as OPEN for all latam ABO's with NOT_QUALIFIED status
    return data.bonusHistory.map((el) =>
      el.status === QUALIFICATION_STATUS.NOT_QUALIFIED && isLatamMarketAndNotPastPeriod(el.period)
        ? { status: "OPEN", period: el.period }
        : el,
    ) as BonusHist[];
  };

  // hide tile if requirement is status is false
  const getVisibilityClass = (visible: boolean) => {
    return visible === false ? "--hidden" : "";
  };

  const getStatusClass = (status: boolean) => {
    return status === true ? " ptb__exception" : "";
  };

  // Will refactor these to a common util to be used by all pages in near future.
  const getErrorStatus = (): boolean => {
    if (data.showDateError) {
      return true;
    }
    return errorStatus;
  };

  const ReturnErrorComponent = () => {
    let errorType = ERROR_TYPE.GENERIC_ERROR;
    if (data.showDateError === true) {
      errorType = ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD;
    }

    return <ErrorPage errorType={errorType} isLoading={isLoading} />;
  };

  const handleViewEligibilityModalClick = () => {
    setShowModal(true);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PATH_TO_BRONZE,
      "",
      "view_eligibility",
    );
  };

  return (
    <main className="ptb">
      <BronzeBannerNewPyToast />
      <div className={`ptb__error ${!getErrorStatus() ? "ptb__hidden" : ""}`}>
        <ReturnErrorComponent />
      </div>
      <div hidden={getErrorStatus()}>
        {/* todo: cleanup the code after bronze incentive page went live */}
        {showHeaderFooter === true ? (
          <div className="ptb__header">
            <h1 id="abo-activation-header">{`${t("pageTitle", defaultContent["pageTitle"])}`}</h1>
            <span className="ptb__long-date">
              <TimeStamp dateFormat={"long"} />
            </span>
          </div>
        ) : (
          <></>
        )}
        {/* todo: cleanup the code after bronze incentive page went live */}
        <section className={`ptb__container${showHeaderFooter === true ? "" : " no-padding"}`}>
          <section className="ptb__row ptb__banner-row" id="abo-activation-content">
            <div
              className={
                `ptb__banner${data.held || data.denied ? "--restrictSize" : ""}` +
                getStatusClass(ineligibleForPY || graduated)
              }
            >
              <PtbBannerTile
                data={{ ...data, ...trackingRequirementData }}
                isLoading={isLoading}
                selectedPeriod={selectedPeriod}
                extraBonusesMet={trackingRequirementData.extraBonusesMet}
              ></PtbBannerTile>
            </div>
            <div className={"ptb__row-tile" + getStatusClass(hasExceptionStatus || ineligibleForPY || graduated)}>
              <TrackingRequirementTile
                isLoading={isLoading}
                trackingRequirementData={trackingRequirementData}
                legReqVolData={getLegRequiredVolume(data.legRequiredVolume)}
                bonusCurrency={data.bonusCurrency}
              />
            </div>
            <div
              className={
                "ptb__row-tile" + getVisibilityClass(data.perfBonusLevel.required) + getStatusClass(hasExceptionStatus)
              }
            >
              <EligibilityTile
                data={data}
                isLoading={isLoading}
                period={selectedPeriod}
                showEligibilityButton={!ineligibleForPY}
                handleButtonClick={handleViewEligibilityModalClick}
              />
            </div>
          </section>

          <section className={"ptb__monthlyReq" + getStatusClass(hasExceptionStatus || ineligibleForPY || graduated)}>
            <TitleSkeletonLoader isLoading={isLoading} width="18.75rem" height="1.5rem">
              <h3 className="ptb__section-title">{`${t("monthlyReqHeader", defaultContent["monthlyReqHeader"])}`}</h3>
            </TitleSkeletonLoader>
            <section className="ptb__row ptb__requirements-row" id="reqTileRow">
              <div id="legReqTile" className={"ptb__row-tile" + getVisibilityClass(true)}>
                <LegRequirementTile
                  isLoading={isLoading}
                  legRequirements={data.legRequirements}
                  bonusCurrency={data.bonusCurrency}
                  country={data.country}
                  volumeNeeded={volumeNeeded}
                  volumeType={volumeTypeLabel.toString()}
                  newLegTenureMonths={data.newLegTenureMonths}
                ></LegRequirementTile>
              </div>
              <div className={"ptb__row-tile" + getVisibilityClass(data.perfBonusLevel.required)}>
                <VolumeRequirementTile
                  title={`${t("performanceBonusReq.title", defaultContent.performanceBonusReq["title"], {
                    perfBonus: GPVTile.requiredPerfLevel,
                  })}`}
                  met={GPVTile.met}
                  progressLabel={`${t(
                    "performanceBonusReq.progressSubtitle",
                    defaultContent.performanceBonusReq["progressSubtitle"],
                    {
                      targetGPV: formatVolume(GPVTile.targetAmount),
                    },
                  )}`}
                  volumeNeededLabel={`${t("performanceBonusReq.tag", defaultContent.performanceBonusReq["tag"], {
                    neededGPV: formatVolume(GPVTile.neededAmount > 0 ? GPVTile.neededAmount : 0),
                    volumeType: LeafVolumeLabel("group"),
                  })}`}
                  isLoading={isLoading}
                  currentAmount={GPVTile.currentAmount}
                  targetAmount={GPVTile.targetAmount}
                  circleCheckmarkId={"volumeRequirementTile__gpv"}
                  volumeType="group"
                />
              </div>
              <div className={"ptb__row-tile" + getVisibilityClass(displayVCS)}>
                <VcsPercentRequirementTile
                  isLoading={isLoading}
                  additionalNeeded={VCSTile.additionalNeeded}
                  targetValue={VCSTile.target}
                  shouldShowGraphics={true}
                  leftValue={VCSTile.vcs}
                  rightValue={VCSTile.ppv}
                  met={VCSTile.met}
                />
              </div>
              <div className={"ptb__row-tile" + getVisibilityClass(data.personalVolume.required)}>
                <VolumeRequirementTile
                  title={`${t("ppvReq.title", defaultContent.ppvReq["title"], {
                    targetPPV: formatVolume(PPVTile.targetAmount),
                    volumeType: LeafVolumeLabel(PPVTile.volumeType),
                  })}`}
                  met={PPVTile.met}
                  progressLabel={`${t("ppvReq.progressSubtitle", defaultContent.ppvReq["progressSubtitle"], {
                    targetPPV: formatVolume(PPVTile.targetAmount),
                  })}`}
                  volumeNeededLabel={`${t("ppvReq.tag", defaultContent.ppvReq["tag"], {
                    neededPPV: formatVolume(PPVTile.neededAmount > 0 ? PPVTile.neededAmount : 0),
                    volumeType: LeafVolumeLabel(PPVTile.volumeType),
                  })}`}
                  isLoading={isLoading}
                  currentAmount={PPVTile.currentAmount}
                  targetAmount={PPVTile.targetAmount}
                  circleCheckmarkId={"volumeRequirementTile__ppv"}
                  volumeType={PPVTile.volumeType}
                />
              </div>
            </section>
          </section>

          <section className={"ptb__bonusCalcReq" + getStatusClass(hasExceptionStatus || ineligibleForPY || graduated)}>
            <TitleSkeletonLoader isLoading={isLoading} width="18.75rem" height="1.5rem">
              <h3 id="bonusCalcTitle" className="ptb__section-title">
                {`${t("bonusCalcHeader", defaultContent["bonusCalcHeader"])}`}
              </h3>
            </TitleSkeletonLoader>
            <section className="ptb__row">
              <div className="ptb__row-tile">
                <CalcDescTile
                  isLoading={isLoading}
                  locale={locale}
                  data={data}
                  volumeNeeded={volumeNeeded}
                  volumeType={LeafVolumeLabel(data.legRequiredVolume.requirement)}
                />
              </div>
            </section>
          </section>
          {/* todo: cleanup the code after bronze incentive page went live */}
          {showHeaderFooter === true ? <BonusInfoFooter /> : <></>}
        </section>
        <EligibilityModal
          title={`${t("eligibleModal.title", defaultContent["eligibleModal"]["title"])}`}
          show={showModal}
          onCloseClick={hideModal}
          bonusHistory={getHistory()}
          awardCap={data.awardCap}
          selectedPeriod={selectedPeriod}
          id={"ptb-eligiblity-modal"}
        />
      </div>
    </main>
  );
}
