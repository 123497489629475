import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import "./BronzeIncentivesLoader.scss";

const BronzeIncentivesLoader = () => {
  return (
    <div className="bronze-incentives-loader-container">
      <div className="bronze-incentives-loader-container__row bronze-incentives-loader-container__first">
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
      </div>
      <div className="bronze-incentives-loader-container__header">
        <LeafRectangleLoader isLoading={true} numRows={1} width="18.75rem" height="1.5rem" />
      </div>
      <div className="bronze-incentives-loader-container__row bronze-incentives-loader-container__second">
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
      </div>
    </div>
  );
};

export default BronzeIncentivesLoader;
