import { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { IconClose } from "../../icons/IconClose/IconClose";
import { lockBodyScrolling } from "../../util/cssUtils";
import "./LeafPanel.scss";
import { IconLongHyphen } from "../../icons/IconLongHyphen/IconLongHyphen";
import { FocusTrap } from "../../components/FocusTrap/FocusTrap";
import commonContent from "../../../content/common";

export type ModalProps = {
  show: boolean;
  onCloseClick: Function;
  title: string;
  children?: ReactNode;
  position?: "right" | "left";
  exitIcon?: ReactNode;
  id: string;
  footer?: ReactNode;
  exitIconAriaLabel?: string;
  background?: "" | "cloud" | "veryLightGrey";
  showBackdrop?: boolean;
  previousElement?: HTMLElement;
  lockBackgroundScrolling?: boolean;
};

const LeafPanel = ({
  show,
  onCloseClick,
  title,
  children,
  position = "right",
  exitIcon,
  id,
  footer = null,
  exitIconAriaLabel = "",
  background = "",
  showBackdrop = true,
  lockBackgroundScrolling = true,
  previousElement,
}: ModalProps) => {
  const { t } = useTranslation(["common"]);
  const [modalOpenedByElement, setModalOpenedByElement] = useState<Element>();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonText = t("closeButton", commonContent["closeButton"]).toString();
  if (!exitIcon) {
    exitIcon = <IconClose width={"0.8125rem"} height={"1.25rem"} />;
  }
  if (!exitIconAriaLabel) {
    exitIconAriaLabel = closeButtonText;
  }

  useEffect(() => {
    if (lockBackgroundScrolling === true) lockBodyScrolling(show);
    if (show) {
      // capture the button that opened the modal so we can refocus it when modal closes
      if (previousElement) {
        setModalOpenedByElement(previousElement);
      } else if (document.activeElement instanceof HTMLButtonElement) {
        setModalOpenedByElement(document.activeElement);
      }

      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    }
  }, [previousElement, show, lockBackgroundScrolling]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    onCloseClick(false);
    if (modalOpenedByElement) {
      (modalOpenedByElement as HTMLElement).focus();
      setModalOpenedByElement(undefined);
    }
  };

  const modalBackdrop = (
    <div
      tabIndex={-1}
      onClick={handleOnClose}
      className={`leaf-panel__backdrop ${showBackdrop ? "backdrop" : ""}`}
      id={id}
    ></div>
  );

  return show ? (
    <div
      className={`leaf-panel-modal__mobile-background${show === true ? "--displayed" : "--dismissed"}`}
      tabIndex={-1}
    >
      {show && ReactDOM.createPortal(modalBackdrop, document.body)}
      <FocusTrap
        className={`leaf-panel__${position} leaf-panel${
          show === true ? "--displayed" : "--dismissed"
        } leaf-panel__bg--${background}`}
        aria-modal={true}
        aria-hidden={!show}
        tabIndex={0}
        role="dialog"
        handleOnClose={handleOnClose}
        aria-label={title}
      >
        {footer && (
          <div className="leaf-panel__drawer">
            <IconLongHyphen />
          </div>
        )}
        <div className="leaf-panel__header">
          <h1 className="leaf-panel__header-title">{title}</h1>
          <button
            tabIndex={0}
            ref={closeButtonRef}
            className="leaf-panel__closeBtn"
            aria-label={exitIconAriaLabel}
            onClick={handleOnClose}
            id="leafPanelCloseButton"
            onKeyDown={handleKeyDown}
          >
            {exitIcon}
          </button>
        </div>

        <div className="leaf-panel__body">{children}</div>
        {footer && <div className="leaf-panel__footer">{footer}</div>}
      </FocusTrap>
    </div>
  ) : (
    <></>
  );
};

export default LeafPanel;
