import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ComponentRoutes } from "./routing/Routing";
import { CustomersRoutes } from "./routing/CustomersRouting";
import { persistor, store } from "./store";
import { MBIRoutes } from "./routing/MBIRouting";
import { IncentiveRoutes } from "./routing/IncentiveRouting";
import { VisionBonusRoutes } from "./routing/VisionBonusRouting";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Suspense>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              {ComponentRoutes()}
              {CustomersRoutes()}
              {MBIRoutes()}
              {IncentiveRoutes()}
              {VisionBonusRoutes()}
            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
