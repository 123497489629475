const content: { [key: string]: any } = {
  pageTitle: "QC Growth Incentive+",
  bannerTile: {
    tracking: "Keep going to earn the QC Growth Incentive+.",
    earned: "Great Job! You are earning QC Growth Incentive+.",
    notTracking: "You are not currently tracking to achieve the QC Growth Incentive+.",
    denied: "Your PY{performanceYear} business is not eligible for the QC Growth Incentive+.",
    noLongerEligible: "Your PY{performanceYear} business is not eligible for the QC Growth Incentive+.",
    manuallyApproved: "The QC Growth Incentive+ has been manually approved.",
    held: "Your award is on hold.",
    contactCustomerService: "If you require more information, please contact Customer Support for further details.",
    description:
      "The QC Growth Incentive+ rewards you for developing leaders within your group. Qualification credits (QCs) are awarded based on the number of ABOs within your group that qualifies for Founders Platinum or above. Please contact your market representative for more details.",
    potentialEarnings: "Potential PY{performanceYear} Earnings",
  },
  progressTile: {
    title: "Track your progress",
    requirementLabel: "You've met <1>{current} of {needed}</1> requirements to earn this incentive.",
  },
  incentiveCalc: {
    sectionHeader: "How is this incentive calculated?",
    incentive: {
      base: "Multiplier Base",
      multipler: "Payout Ratio",
      total: "Potential QCGI Amount",
    },
  },
  requirements: {
    sectionHeader: "How do I qualify? Here is the list of annual requirements.",
    diamondBonus: {
      title: "Qualified Diamond Bonus Recipient",
      description: "",
    },
    maintainGar: {
      title: "Maintain GAR Award",
      description: "",
    },
    maintainQc: {
      title: "Maintain/Increase QCs",
      description: "",
      previousYearTile: {
        title: "Compare with Previous Year:",
        previousPyHeader: "PY{performanceYear} Status",
        currentPyHeader: "Current YTD",
      },
    },
  },
  multiplierBase: {
    title: "PY{performanceYear} Multiplier Base",
    bonus: "Bonus",
    amount: "Amount",
    performanceBonus: "Performance Bonus",
    rubyBonus: "Ruby Bonus",
    leadershipBonus: "Leadership Bonus",
    fosterBonus: "Foster Bonus",
    depthBonus: "Depth Bonus",
    totalBonusLabel: "Total Multiplier Base",
  },
  qcMultiplier: {
    title: "QC Growth Incentive Payout Ratio",
    potentialMultiplier: "Potential Multiplier = {multiplier}",
    garAward: "GAR Award",
    maintain: "Maintain",
    qcRange: "{start}-{end} QC",
    qcMax: "{max} QC+",
    executiveDiamond: "Executive Diamond & Founders Executive Diamond",
    doubleDiamond: "Double Diamond & Founders Double Diamond",
    tripleDiamond: "Triple Diamond & Founders Triple Diamond",
    crown: "Crown & Founders Crown",
    crownAmbassador: "Crown Ambassador & Founders Crown Ambassador",
    footnote: '*QC Growth less than 1.0 regards is considered as "Maintain".',
  },
} as const;

export default content;
