import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../content/leadershipSeminar";
import "./LeadershipSeminar.scss";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { useAppDispatch, useAppSelector } from "../../store";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../common/components/ErrorTile/ErrorTile";
import { LSBanner } from "./LSBanner/LSBanner";
import { LSRequirements } from "./LSRequirements/LSRequirements";
import { FetchLSOverviewData } from "./LeadershipSeminarDataLayer";
import PQRequirementsSection from "./PQRequirements/PQRequirements";

export function LeadershipSeminar() {
  const { t } = useTranslation(["leadershipSeminar"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const dispatch = useAppDispatch();

  const { lsOverviewData, isLoading, errorStatus, refetch } = FetchLSOverviewData(selectedPeriod);

  const { hideRequirements, Status, PQRequirementsData } = lsOverviewData;

  const totalRequirements =
    lsOverviewData.AnnualRequirements.TotalRequirements + lsOverviewData.MonthlyRequirements.TotalRequirements;
  const totalRequirementsMet =
    lsOverviewData.AnnualRequirements.RequirementsMet + lsOverviewData.MonthlyRequirements.RequirementsMet;

  const automaticallyApproved = lsOverviewData.AutomaticallyApproved;

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  if (errorStatus) {
    return (
      <LeafTile hideBorder={true}>
        <ErrorTile clickFunction={refetch} errorStatus={errorStatus} isLoading={false} />
      </LeafTile>
    );
  }

  return (
    <main className="LeadershipSeminar">
      <LSBanner
        Status={automaticallyApproved ? QUALIFICATION_STATUS.AUTOMATICALLY_APPROVED : Status}
        isLoading={isLoading}
      />
      {automaticallyApproved || totalRequirements === 0 ? (
        <></>
      ) : (
        <div className="LeadershipSeminar__pageTitle">
          <h1 className="LeadershipSeminar__pageTitle--title">
            <Trans>{`${t("pageTitle.title", String(defaultContent["pageTitle"]["title"]))}`}</Trans>
          </h1>
          <div className="LeadershipSeminar__pageTitle--timestamp">
            <TimeStamp dateFormat={"long"} />
          </div>
        </div>
      )}

      {automaticallyApproved || totalRequirements === 0 ? (
        <></>
      ) : (
        <div className="LeadershipSeminar__requirementsSection">
          <h2 className="LeadershipSeminar__requirementsSection--title">
            {`${t("requirements.sectionHeader", String(defaultContent["requirements"]["sectionHeader"]))}`}
          </h2>
          <p className="LeadershipSeminar__requirementsSection--description">
            <Trans>
              {t("requirements.description", defaultContent.requirements["description"], {
                current: totalRequirementsMet,
                needed: totalRequirements,
              })}
              <span
                className={totalRequirements === totalRequirementsMet ? "Numbers__bold Numbers__met" : "Numbers__bold"}
              ></span>
            </Trans>
          </p>
          {hideRequirements ? <></> : <LSRequirements lsOverviewResponse={lsOverviewData} isLoading={isLoading} />}
          {PQRequirementsData.ShowPQRequirement ? (
            <div className="LeadershipSeminar__requirementsSection--pqTile">
              <PQRequirementsSection
                pqRequirements={PQRequirementsData}
                isLoading={isLoading}
                showPQRequirementsTile={PQRequirementsData.ShowPQRequirement}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      <div
        className={`LeadershipSeminar__footer ${
          automaticallyApproved ? "LeadershipSeminar--automaticallyApproved" : ""
        }`}
      >
        <BonusInfoFooter />
      </div>
    </main>
  );
}
