import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/aboProfileSlider";
import { useAppDispatch, useAppSelector } from "../../../store";
import { hideProfile } from "../../../reducers/profile";
import { closeProfileClickTrack } from "../../../common/tealium/ABOProfile/Profile/IndexTrack";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import ABOProfileBody from "./ABOProfileBody/ABOProfileBody";

export type Props = {
  previousElement?: HTMLElement;
};

const ABOProfileSlider = (props: Props) => {
  const { previousElement } = props;
  const { t } = useTranslation(["aboProfileSlider", "common"]);
  const isShowProfile = useAppSelector((state) => state.profile.isShowProfile);
  const dispatch = useAppDispatch();
  const contentRef = useRef<HTMLDivElement>(null);

  const closeProfile = () => {
    dispatch(hideProfile());
    closeProfileClickTrack();
  };

  // Prevents the modal from opening then closing right away when using keyboard space or enter.
  useEffect(() => {
    if (isShowProfile && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isShowProfile]);

  return (
    <LeafPanel
      title={t("aboProfile", defaultContent["aboProfile"])}
      show={isShowProfile}
      onCloseClick={closeProfile}
      id={"abo-profile-slider"}
      previousElement={previousElement}
    >
      <div ref={contentRef} tabIndex={-1}>
        <ABOProfileBody isShowProfile={isShowProfile} />
      </div>
    </LeafPanel>
  );
};

export default ABOProfileSlider;
