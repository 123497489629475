import { useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import "./PerfPlusElite.scss";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import defaultContent from "../../content/ppe";
import { ROUTE_LABELS } from "../../common/enums/routing-enums";
import { BannerTile } from "../../common/components/BannerTile/BannerTile";
import { TrackProgressTile } from "../../common/components/TrackProgressTile/TrackProgressTile";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { getPerformanceIncentive } from "../../routing/MenuConfig/leaderGrowthDropdown";
import { IncentiveCalculation } from "../../common/components/IncentiveCalculation/IncentiveCalculation";
import { RequirementTileMapper } from "../../common/components/RequirementTileMapper/RequirementTileMapper";
import { Requirements } from "../../common/interfaces/coreplusIncentives";
import { mockPPEData } from "./mockPPEData";
import PerformancePlusEliteLit from "./PerfPlusEliteLit";
import { RubyPerformanceTile } from "./RubyPerformanceTile/RubyPerformanceTile";
import {
  mockPerfEliteRubyInProgress,
  mockPerfPlusRubyInProgress,
  mockPerfPlusRubyMet,
} from "./RubyPerformanceTile/RubyPerformanceMockData";

const getPageTitle = (titleKey: string, translate: TFunction) => {
  const ppeKeys: string[] = [ROUTE_LABELS.PPE, ROUTE_LABELS.PERFORMANCE_PLUS, ROUTE_LABELS.PERFORMANCE_ELITE];
  const ppeTitle = ppeKeys.includes(titleKey)
    ? translate(`pageTitle.${titleKey}`, String(defaultContent["pageTitle"][titleKey]))
    : translate(`${titleKey}`);

  return ppeTitle;
};

export default function PerformancePlusElite() {
  const { t } = useTranslation(["ppe"]);
  const { shellConfig } = useAppSelector(({ boot }) => boot);
  const dispatch = useAppDispatch();

  const { search, state } = useLocation();
  const { navigation } = useAppSelector((state) => state.boot.configuration);
  const queryParams = new URLSearchParams(search);
  const showOldPpe = queryParams.get("showOldPpe") ?? navigation.showOldPpe;

  const [titleKey, setTitleKey] = useState<string>(state?.titleKey || ROUTE_LABELS.PPE);
  const pageTitle = getPageTitle(titleKey, t);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useEffect(() => {
    if (shellConfig.eligibleIncentives !== undefined) {
      const { performanceLabel } = getPerformanceIncentive(shellConfig.eligibleIncentives);
      setTitleKey(performanceLabel);
    }
  }, [shellConfig]);

  if (showOldPpe) {
    return <PerformancePlusEliteLit />;
  }

  return (
    <main className="PPE">
      <PageTitleSection title={pageTitle} />
      <div className="PPE__body">
        <section className="PPE__tracking-section">
          <div className="PPE__tracking-section--banner-tile">
            <BannerTile
              isLoading={false}
              title={t(
                `bannerTile.performancePlus.notTracking`,
                defaultContent.bannerTile.performancePlus["notTracking"],
                { monthYear: "XXXX 20XX" },
              )}
              description={t(`bannerTile.description`, defaultContent.bannerTile["description"], {})}
              shouldDisplayAmount={true}
              bonusAmount={"$XXX"}
              bonusAmountLabel={"Potential XXXX 20XX Earnings"}
              learnMoreLink="google.com"
            />
          </div>
          <div className="PPE__tracking-section--tracking-tile">
            <TrackProgressTile
              isLoading={false}
              title={t(`progressTile.title`, defaultContent.progressTile["title"], {})}
              circlesSection={<div>Tracking circles</div>}
            />
          </div>
        </section>
        <section className="PPE__incentive-section">
          <h2>{t("incentiveCalc.sectionHeader", String(defaultContent.incentiveCalc.sectionHeader))}</h2>
          <IncentiveCalculation
            isLoading={false}
            base={{
              title: "July Ruby PV",
              amount: "XXX",
            }}
            multiplier={{
              title: "Performance Plus Incentive+ Multiplier",
              amount: "X",
            }}
            total={{
              title: "Current Total",
              amount: "XXX",
            }}
          />
        </section>
        <section className="PPE__requirements-section">
          <h2>{t("requirements.sectionHeader", String(defaultContent.requirements.sectionHeader))}</h2>

          <div className="PPE__requirements-section--tiles">
            <RequirementTileMapper
              isLoading={false}
              requirements={mockPPEData.Requirements as Requirements}
              origin={"fgg"}
            />
            <RubyPerformanceTile
              isLoading={false}
              rubyRequirements={
                titleKey === ROUTE_LABELS.PERFORMANCE_PLUS
                  ? [mockPerfPlusRubyInProgress]
                  : [mockPerfPlusRubyMet, mockPerfEliteRubyInProgress]
              }
            />
          </div>
        </section>
      </div>
      <section className="PPE__footer">
        <BonusInfoFooter />
      </section>
    </main>
  );
}
