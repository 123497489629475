import { AWARD_CODES } from "../../common/enums/award-codes";
import { DROPDOWN_KEYS, DROPDOWN_LABELS, ROUTE_LABELS, ROUTE_PATHS } from "../../common/enums/routing-enums";
import { NavData, NavDropdown } from "../../common/interfaces/routing";
import BronzeBuilder from "../../components/BronzeBuilder/BronzeBuilder";
import BronzeFoundation from "../../components/BronzeFoundation/BronzeFoundation";
import CSI from "../../components/CustomerSalesIncentive/CustomerSalesIncentive";
import PathToBronze from "../../components/BronzeIncentives/PathToBronze/PathToBronze";
import { StrongStart } from "../../components/StrongStart/StrongStart";
import { filterDropdown } from "./createMenuConfig";

export const EARLY_INCENTIVES_COMPONENT_MAP = {
  [ROUTE_PATHS.CSI]: <CSI />,
  [ROUTE_PATHS.PATH_TO_BRONZE]: <PathToBronze />,
  [ROUTE_PATHS.BRONZE_FOUNDATION]: <BronzeFoundation />,
  [ROUTE_PATHS.BRONZE_BUILDER]: <BronzeBuilder />,
  [ROUTE_PATHS.STRONG_START_INCENTIVE]: <StrongStart />,
};

export const createEarlyIncentivesDropdown = (navConfigs: NavData[]): NavDropdown => {
  const earlyIncentivesDropdown = {
    label: DROPDOWN_LABELS.EARLY_INCENTIVES,
    key: DROPDOWN_KEYS.EARLY_INCENTIVES,
    // incentives should be sorted in ascending order based on award code
    items: [
      {
        label: ROUTE_LABELS.PATH_TO_BRONZE,
        path: ROUTE_PATHS.PATH_TO_BRONZE,
        class: ROUTE_PATHS.PATH_TO_BRONZE,
        code: AWARD_CODES.PATH_TO_BRONZE,
        navPosition: 1,
      },
      {
        label: ROUTE_LABELS.STRONG_START_INCENTIVE,
        path: ROUTE_PATHS.STRONG_START_INCENTIVE,
        class: ROUTE_PATHS.STRONG_START_INCENTIVE,
        code: AWARD_CODES.STRONG_START_INCENTIVE,
        navPosition: 2,
      },
      {
        label: ROUTE_LABELS.CSI,
        path: ROUTE_PATHS.CSI,
        class: ROUTE_PATHS.CSI,
        code: AWARD_CODES.CSI,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.BF_PLUS,
        path: ROUTE_PATHS.BRONZE_FOUNDATION,
        class: ROUTE_PATHS.BRONZE_FOUNDATION,
        code: AWARD_CODES.BRONZE_FOUNDATION,
        navPosition: 4,
      },
      {
        label: ROUTE_LABELS.BB_PLUS,
        path: ROUTE_PATHS.BRONZE_BUILDER,
        class: ROUTE_PATHS.BRONZE_BUILDER,
        code: AWARD_CODES.BRONZE_BUILDER,
        navPosition: 5,
      },
      {
        label: ROUTE_LABELS.BRONZE_INCENTIVES,
        path: ROUTE_PATHS.BRONZE_INCENTIVES,
        class: ROUTE_PATHS.BRONZE_INCENTIVES,
        code: AWARD_CODES.BRONZE_INCENTIVES,
        navPosition: 6,
      },
    ],
  };

  return filterDropdown(navConfigs, earlyIncentivesDropdown);
};
