export const mockPPEData = {
  IncomeData: {
    PerformanceBonusLevelPercent: "XX%",
    PotentialPYEarnings: "$XXX",
    IncentiveCap: "",
    Status: "NOT_QUALIFIED",
  },
  Requirements: {
    VolumeRequirements: [
      {
        Name: "personal",
        Met: false,
        Exempt: false,
        Volume: {
          Current: {
            Actual: 1048.7,
            Formatted: "XXXX",
          },
          Target: {
            Actual: 1800,
            Formatted: "XXXXX",
          },
          Remaining: {
            Actual: 751.3,
            Formatted: "XXXXX",
          },
        },
      },
      {
        Name: "ruby",
        Met: false,
        Exempt: false,
        Volume: {
          Current: {
            Actual: 10247.59,
            Formatted: "XXXX",
          },
          Target: {
            Actual: 48000,
            Formatted: "XXXXXX",
          },
          Remaining: {
            Actual: 37752.41,
            Formatted: "XXXXXX",
          },
        },
      },
    ],
    PercentRequirements: [],
    BadgeRequirements: [
      {
        Name: "basisCheckForFoundersPlatinumWithEq",
        Met: false,
        Target: "1",
        Status: "TRACKING",
      },
    ],
    TotalRequirements: 4,
    RequirementsMet: 1,
    RequirementMetStatuses: [false, false, true, false],
  },
  IncentiveData: {
    MultiplierData: [
      {
        Type: "Incentive",
        Base: "$XXXXXX",
        Multiplier: "X%",
        Total: "$XXX",
      },
    ],
    TrackingQ: "XX",
    PotentialMultiplier: "X%",
    PreviousPYFQ: "XX",
    CurrentYTDFQ: "XX",
  },
  MultiplierMatrix: {
    ColumnHeaders: ["numFqsPriorYear", "annualMultiplierNonFe", "annualMultiplierFe"],
    Rows: [
      [
        {
          CellContent: "growMax",
          Range: [6],
          Status: "",
        },
        {
          CellContent: "XX%",
          Range: [],
          Status: "",
        },
        {
          CellContent: "XX%",
          Range: [],
          Status: "",
        },
      ],
      [
        {
          CellContent: "growRange",
          Range: [3, 5],
          Status: "",
        },
        {
          CellContent: "XX%",
          Range: [],
          Status: "",
        },
        {
          CellContent: "XX%",
          Range: [],
          Status: "",
        },
      ],
      [
        {
          CellContent: "growRange",
          Range: [1, 2],
          Status: "",
        },
        {
          CellContent: "XX%",
          Range: [],
          Status: "",
        },
        {
          CellContent: "XX%",
          Range: [],
          Status: "",
        },
      ],
      [
        {
          CellContent: "maintain",
          Range: [0],
          Status: "",
        },
        {
          CellContent: "X%",
          Range: [],
          Status: "",
        },
        {
          CellContent: "XX%",
          Range: [],
          Status: "",
        },
      ],
    ],
    Legend: "kickerLabel",
  },
  CallResults: [{}],
  Error: null,
};
