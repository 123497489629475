import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../enums/tealium-enums";

export const additionalIncentiveMetricOnClick = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "payment_additional_incentive",
    TEALIUM.CATEGORY.BRONZE_BUILDER_INCENTIVE,
    "",
    "additional_incentive_metric",
  );
};
