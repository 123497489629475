import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import LeafRectangleLoader from "../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import "./MBI.scss";

const MBI = () => {
  const RouterLoader = () => (
    <div className="router-loader">
      <div className="router-loader-header">
        <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="2rem" />
      </div>
      <div className="router-loader-top">
        <div className="router-loader-top-left">
          <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="16rem" />
        </div>
        <div className="router-loader-top-right">
          <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="7rem" />
          <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="7rem" />
        </div>
      </div>
      <div className="router-loader-bottom">
        <LeafRectangleLoader isLoading={true} numRows={5} width="95%" height="2rem" />
      </div>
    </div>
  );

  return (
    <main className="mbi">
      <Suspense fallback={<RouterLoader />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

export default MBI;
