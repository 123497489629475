import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../../common/enums/tealium-enums";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import { LeafCollapsibleSection } from "../../../../common/leaf/LeafCollapsibleSection/LeafCollapsibleSection";
import defaultContent from "../../../../content/annualPGG";
import { FggOverviewResponse } from "../../../../common/interfaces/groupGrowthIncentives";
import LeafTileDivider from "../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import "./MultiplierDetails.scss";
import { getPerformanceYear } from "../../../../common/util/period";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { MatrixFQ } from "./MatrixFQ/MatrixFQ";
import { PreviousPQ } from "./PreviousPQ/PreviousPQ";
import { CurrentPQ } from "./CurrentPQ/CurrentPQ";

export interface MultiplierDetailsModal {
  displayModal: boolean;
  isLoading: boolean;
  errorStatus: boolean;
  refetch: Function;
  toggleModal: Function;
}

type Props = {
  overviewData: FggOverviewResponse;
  selectedPeriod: string;
  isLoading: boolean;
};

export const MultiplierDetails = ({ overviewData: fggOverviewData, selectedPeriod, isLoading }: Props) => {
  const { t } = useTranslation(["annualPGG"]);
  const currentPerformanceYear = getPerformanceYear(selectedPeriod);
  const { width } = useWindowSize();
  const prevOpenRef = useRef(false);
  const isMobile = width && width <= 600;
  const { IncentiveData } = fggOverviewData;

  const tealiumClickActionToggleMultiplierDetails = (isExpanded: boolean) => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PERSONAL_GROUP_GROWTH,
      `incentive_multiplier_details_${!isExpanded ? "open" : "close"}`,
      "incentive_multiplier_details",
    );
  };

  return (
    <LeafCollapsibleSection
      onClickFn={tealiumClickActionToggleMultiplierDetails}
      isExpanded={!isMobile}
      titleExpand={`${t("multiplierDetails.titleExpand", String(defaultContent.multiplierDetails.titleExpand))}`}
      titleCollapse={`${t("multiplierDetails.titleCollapse", String(defaultContent.multiplierDetails.titleCollapse))}`}
    >
      <div className="MultiplierDetails">
        <LeafRectangleLoader isLoading={isLoading} height="15rem" width="100%">
          <MatrixFQ
            overviewData={fggOverviewData}
            currentPerformanceYear={currentPerformanceYear}
            prevOpenRef={prevOpenRef}
          />
          <LeafTileDivider />
          <PreviousPQ
            previousPQs={IncentiveData.PreviousPYFQ}
            prevOpenRef={prevOpenRef}
            selectedPeriod={selectedPeriod}
          />
          <LeafTileDivider />
          <CurrentPQ
            currentPQs={IncentiveData.CurrentYTDFQ}
            prevOpenRef={prevOpenRef}
            selectedPeriod={selectedPeriod}
          />
        </LeafRectangleLoader>
      </div>
    </LeafCollapsibleSection>
  );
};
