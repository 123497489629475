import "./IconTable.scss";

export const IconTable = ({
  width = "2.5rem",
  height = "2.5rem",
  color = "",
}: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  return <span className={`icon-table icon-table__${color}`} style={{ width, height }} data-testid="icon-table"></span>;
};
