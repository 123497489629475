import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import LeafFilterButton from "../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import "./VisionBonus.scss";

const VisionBonus = () => {
  return (
    <div className="vision-bonus">
      <div className="vision-bonus__header">
        <h1>Vision Bonus</h1>
        <div className="vision-bonus__date">
          <TimeStamp dateFormat={"long"} />
        </div>
      </div>

      <div className="vision-bonus__content">
        <div className={"vision-bonus__content__overview"}>
          <LeafTile isLoading={false} title={"Keep going to earn the Vision Bonus!"} />
          <LeafTile isLoading={false} title={"Track your progress"} />
        </div>
        <div className={"vision-bonus__content__calculation"}>
          <div className={"vision-bonus__content__calculation__container"}>
            <div className={"vision-bonus__content__calculation__header__container"}>
              <h2 className={"vision-bonus__content__calculation__header__container__header"}>
                How is the base amount calculated?
              </h2>
              <span className={"vision-bonus__content__calculation__header__container__sub-header"}>
                The first year of qualification for A70 Vision Bonus is PY26. The base amount will be based on much you
                have earned for FGI or QCGI in PY25 and PY26.
              </span>
            </div>
            <div className={"vision-bonus__content__calculation__menu"}>
              <LeafFilterButton text={"FQ Growth"} onButtonClick={() => true} />
              <LeafFilterButton text={"QC Growth"} onButtonClick={() => true} />
            </div>
            <div className={"vision-bonus__content__calculation__content"}>
              <LeafTile isLoading={false} title={"FC Growth Multiple base"} />
              <LeafTile isLoading={false} title={"FC Growth Multiple"} />
              <LeafTile isLoading={false} title={"Potential PY25 FQ Growth Amount"} />
            </div>
          </div>
        </div>
        <div className={"vision-bonus__content__requirement"}>
          <h2 className={"vision-bonus__content__requirement__header"}>
            How do I qualify? Here is the list of annual requirements.
          </h2>
          <div className={"vision-bonus__content__requirement__content"}>
            <LeafTile isLoading={false} title={"Maintain Pin"} />
            <LeafTile isLoading={false} title={"FC or QC Growth"} />
          </div>
        </div>
      </div>

      <div className="vision-bonus__footer">
        <BonusInfoFooter />
      </div>
    </div>
  );
};

export default VisionBonus;
